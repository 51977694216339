<template>
  <div class="row justify-content-center mt-4">
    <div v-if="getCurrencyPrices.success" class="col-12 mb-4">
      <div>
        {{ $t("lastUpdate") }}:
        <strong>{{
          formatter(new Date(getCurrencyPrices.date), {
            day: "numeric",
            month: "long",
            year: "numeric",
          })
        }}</strong>
      </div>
      <div>
        <span class="badge badge-info"
          >1 EUR = {{ getCurrencyPrices.rates.TRY?.toFixed(2) }} TRY</span
        >
        <span class="badge mx-2 badge-info"
          >1 EUR = {{ getCurrencyPrices.rates.USD?.toFixed(2) }} USD</span
        >
      </div>
    </div>
    <div class="col-lg-3 col-md-6 col-12">
      <div class="vid-title">
        <h2 class="title-hd">{{ $t("enterProductPrice") }} (TRY) *</h2>
        <div class="form_field">
          <input
            required
            v-model="form.priceTRY"
            type="number"
            :min="minPrice"
            :placeholder="computedPlaceholder.try"
          />
        </div>
        <div v-if="computedPlaceholder.try !== '₺ 0'">
          <small class="text-info"
            >Önerilen: {{ computedPlaceholder.try }}</small
          >
        </div>
      </div>
      <div class="vid-title mt-4">
        <h2 class="title-hd">{{ $t("enterProductDiscount") }} (TRY) *</h2>
        <div class="form_field">
          <input
            required
            v-model="form.discountTRY"
            type="number"
            :min="0"
            :max="100"
            :placeholder="$t('discountPer')"
          />
        </div>
      </div>
      <div
        class="vid-title mt-2 text-center"
        v-if="form.discountTRY > 0 && form.priceTRY > 0"
      >
        <span>({{ form.discountTRY }}%) </span>
        <h1>=</h1>
        <h3 class="text-success">
          {{ ((form.priceTRY * (100 - form.discountTRY)) / 100).toFixed(2) }}
        </h3>
      </div>
    </div>
    <div class="col-lg-3 col-md-6 col-12">
      <div class="vid-title">
        <h2 class="title-hd">{{ $t("enterProductPrice") }} (EUR) *</h2>
        <div class="form_field">
          <input
            required
            v-model="form.priceEUR"
            type="number"
            :min="minPrice"
            :placeholder="computedPlaceholder.eur"
          />
        </div>
        <div v-if="computedPlaceholder.eur !== '€ 0'">
          <small class="text-info"
            >Önerilen: {{ computedPlaceholder.eur }}</small
          >
        </div>
      </div>
      <div class="vid-title mt-4">
        <h2 class="title-hd">{{ $t("enterProductDiscount") }} (EUR) *</h2>
        <div class="form_field">
          <input
            required
            v-model="form.discountEUR"
            type="number"
            :min="0"
            :max="100"
            :placeholder="$t('discountPer')"
          />
        </div>
      </div>
      <div
        class="vid-title mt-2 text-center"
        v-if="form.discountEUR > 0 && form.priceEUR > 0"
      >
        <span>({{ form.discountEUR }}%) </span>
        <h1>=</h1>
        <h3 class="text-success">
          {{ ((form.priceEUR * (100 - form.discountEUR)) / 100).toFixed(2) }}
        </h3>
      </div>
    </div>
    <div class="col-lg-3 col-md-6 col-12">
      <div class="vid-title">
        <h2 class="title-hd">{{ $t("enterProductPrice") }} (USD) *</h2>
        <div class="form_field">
          <input
            required
            v-model="form.priceUSD"
            type="number"
            :min="minPrice"
            :placeholder="computedPlaceholder.usd"
          />
        </div>
        <div v-if="computedPlaceholder.usd !== '$ 0'">
          <small class="text-info"
            >Önerilen: {{ computedPlaceholder.usd }}</small
          >
        </div>
      </div>
      <div class="vid-title mt-4">
        <h2 class="title-hd">{{ $t("enterProductDiscount") }} (USD) *</h2>
        <div class="form_field">
          <input
            required
            v-model="form.discountUSD"
            type="number"
            :min="0"
            :max="100"
            :placeholder="$t('discountPer')"
          />
        </div>
      </div>
      <div
        class="vid-title mt-2 text-center"
        v-if="form.discountUSD > 0 && form.priceUSD > 0"
      >
        <span>({{ form.discountUSD }}%) </span>
        <h1>=</h1>
        <h3 class="text-success">
          {{ ((form.priceUSD * (100 - form.discountUSD)) / 100).toFixed(2) }}
        </h3>
      </div>
    </div>
  </div>
</template>

<script>
import { formatter } from "gurhan/helpers/Date";
import { mapGetters } from "vuex";
import Modules from "../../mixins/Modules.js";
export default {
  mixins: [Modules],
  props: {
    value: {
      default: () => ({
        discountEUR: 0,
        discountTRY: 0,
        discountUSD: 0,
        priceEUR: 0,
        priceTRY: 0,
        priceUSD: 0,
      }),
    },
  },
  computed: {
    ...mapGetters(["getCurrencyPrices"]),
    computedPlaceholder() {
      if (this.form.priceTRY > 0) {
        return {
          eur:
            "€ " +
            Number(
              this.form.priceTRY /
                (this.getCurrencyPrices.success
                  ? this.getCurrencyPrices.rates.TRY
                  : 1)
            )?.toFixed(2),
          try: "₺ " + Number(this.form.priceTRY).toFixed(2),
          usd:
            "$ " +
            Number(
              this.form.priceTRY /
                (this.getCurrencyPrices.success
                  ? this.getCurrencyPrices.rates.TRY /
                    this.getCurrencyPrices.rates.USD
                  : 1)
            )?.toFixed(2),
        };
      } else if (this.form.priceEUR > 0) {
        return {
          eur: "€ " + Number(this.form.priceEUR).toFixed(2),
          try:
            "₺ " +
            Number(
              this.form.priceEUR * this.getCurrencyPrices.rates.TRY
            )?.toFixed(2),
          usd:
            "$ " +
            Number(
              this.form.priceEUR * this.getCurrencyPrices.rates.USD
            )?.toFixed(2),
        };
      } else if (this.form.priceUSD > 0) {
        return {
          eur:
            "€ " +
            Number(
              this.form.priceUSD /
                (this.getCurrencyPrices.success
                  ? this.getCurrencyPrices.rates.USD
                  : 1)
            )?.toFixed(2),
          try:
            "₺ " +
            Number(
              this.form.priceUSD *
                (this.getCurrencyPrices.success
                  ? this.getCurrencyPrices.rates.TRY /
                    this.getCurrencyPrices.rates.USD
                  : 1)
            )?.toFixed(2),
          usd: "$ " + Number(this.form.priceUSD).toFixed(2),
        };
      }
      return {
        eur: "€ 0",
        try: "₺ 0",
        usd: "$ 0",
      };
    },
  },
  data() {
    return {
      form: {
        discountEUR: 0,
        discountTRY: 0,
        discountUSD: 0,
        priceEUR: 0,
        priceTRY: 0,
        priceUSD: 0,
      },
      minPrice: 0,
    };
  },
  watch: {
    form: {
      deep: true,
      handler(val) {
        this.$emit("input", val);
      },
    },
  },
  methods: {
    formatter,
  },
  mounted() {
    this.form = this.value;
    this.minPrice = this.createFreeProductModule ? 0 : 1;
  },
};
</script>

<style></style>
